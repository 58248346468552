import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useSearchQuery from 'queries/useSearchQuery';
import { useStateContext } from '@activebrands/core-web/contexts/StateContext';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import Heading from 'components/text/Heading';

const List = styled('ul', {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    ...textStyles['Secondary/20_100_-0375'],
});

const SuggestedSearches = ({ $style = {}, suggestedItems = [] }) => {
    const [, setState] = useStateContext();
    const [css] = useStyletron();
    const { headerConfigContent } = useSearchQuery();
    const { suggestedLabel } = headerConfigContent;
    let items;

    items = suggestedItems?.map(item => ({
        id: item.objectID,
        label: item.query,
    }));

    return (
        <div className={css({ ...$style, paddingLeft: '12px', margin: '24px 0' })}>
            {suggestedLabel && (
                <Heading $style={{ marginBottom: '12px' }} as="h4" fontKeys="Misc/12_100_-015">
                    {suggestedLabel}
                </Heading>
            )}
            <List className={css({ flexDirection: 'column', display: 'flex', alignItems: 'start' })}>
                {items.map(item => {
                    return (
                        <li
                            className={css({
                                ':hover': {
                                    color: 'var(--color-text-subtle)',
                                    cursor: 'pointer',
                                    transitionProperty: 'all',
                                    transitionDuration: 'var(--duration-fast)',
                                    transitionTimingFunction: 'var(--ease)',
                                },
                            })}
                            key={item.id}
                            onClick={event => setState(event.target.textContent)}
                        >
                            {item.label}
                        </li>
                    );
                })}
            </List>
        </div>
    );
};

SuggestedSearches.propTypes = {
    $style: PropTypes.object,
    suggestedItems: PropTypes.array,
};

export default SuggestedSearches;
