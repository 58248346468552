import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import { useStateContext } from '@activebrands/core-web/contexts/StateContext';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import Heading from 'components/text/Heading';
import Cookies from 'js-cookie';

const Wrapper = styled('div');

const List = styled('ul', {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    ...textStyles['Secondary/20_100_-0375'],
});

const SearchHistoryListing = ({ $style = {} }) => {
    const [css] = useStyletron();
    const [, setState] = useStateContext();
    const items = Cookies.get('searchHistory');

    if (!items) {
        return null;
    }

    const parsedItems = JSON.parse(items);

    return (
        <Wrapper $style={$style}>
            <Heading
                $style={{ marginBottom: '12px', color: 'var(--color-text-search-header)' }}
                as="h4"
                fontKeys="Misc/12_100_-015"
            >
                {fm('Search history')}
            </Heading>
            <List>
                {parsedItems.map(item => (
                    <li className={css({ cursor: 'pointer' })} key={item} onClick={e => setState(e.target.textContent)}>
                        {item}
                    </li>
                ))}
            </List>
        </Wrapper>
    );
};

SearchHistoryListing.propTypes = {
    $style: PropTypes.object,
};

export default SearchHistoryListing;
