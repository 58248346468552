import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';

const Buttons = styled('div', {
    position: 'relative',
    display: 'flex',
    width: '100%',
    backgroundColor: 'var(--color-bg-search-tabs)',
    borderBottom: '1px solid var(--color-border-search-tabs)',
});

const SearchTypeFilterButtons = ({ $style = {}, buttonStyle = {}, current, items = [] }) =>
    items.length > 0 ? (
        <Buttons $style={$style}>
            {items.map(item => (
                <ThemeButton
                    $style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '16px',
                        padding: '12px 24px',
                        color:
                            current === item.id
                                ? 'var(--color-text-link-search-tabs-highlight)'
                                : 'var(--color-text-link-search-tabs)',
                        borderBottom:
                            current === item.id
                                ? '2px solid var(--color-border-link-search-tabs-highlight)'
                                : '2px solid var(--color-border-link-search-tabs)',

                        ...buttonStyle,
                    }}
                    active={current === item.id}
                    fontKeys={['Misc/14_110_-0175', null, null, null, null, 'Secondary/16_100_-02']}
                    key={item.id}
                    theme="link"
                    onClick={() => item.onClick(item.id)}
                >
                    {item.label}
                    <span>{item.totalHits}</span>
                </ThemeButton>
            ))}
        </Buttons>
    ) : null;

SearchTypeFilterButtons.propTypes = {
    $style: PropTypes.object,
    buttonStyle: PropTypes.object,
    current: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            id: PropTypes.string,
            onClick: PropTypes.func,
        })
    ),
};

export default SearchTypeFilterButtons;
